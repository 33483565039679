.ui .form-group {
    padding-bottom: 3pt;
}

.ui.dividing.header {
    padding-top: 0.5em;
}

p.field-description {
    margin: 0;
}

.select-form-label {
    color: darkgray;
    padding-right: 2px;
}

.select-vehicle {
    min-width: 140px;
    z-index: 100;
}

.select-id {
    min-width: 100px;
}

.value-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
    overflow: auto;
    padding: 0px 5px;
    max-height: 60px;
}

.text-field {
    width: 350px;
    border-color: #ccc;
    margin: 10px 8px;
    border-color: #ccc;
}
.text-field>.MuiOutlinedInput-multiline {
    padding: 0 14px;
    height: 40px
}

.text-field>.MuiFormLabel-root {
    line-height: 0.3
}

.form-group-timetable{
    width: 350px;
    display: flex;
}

.form-group-timetable>label{
    margin: 10px 0;
}

.form-group-timetable>.MuiFormControl-root{
    width: 350px;
    height: 40px;
    margin: 10px 0;
    position: relative;
}

.form-group-timetable>.MuiFormControl-root>.input-label{
    top:-30px !important;
    color: #999 !important;
    background: #fff !important;
    font-size: 12px !important;
    z-index: 1 !important;
}

.form-group-timetable>.text-button {
    margin: 10px 0 ;
}