span.pointer {
    cursor: pointer;
    font-size: 2em;
    margin-top: -1em;
    margin-bottom: -1em;
}

pre.coordinate-panel {
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    position: absolute;
    bottom: 40px;
    right: 20px;
    padding: 5px 10px;
    margin: 0;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    display: none;
}

.map-overlay {
    font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
    position: absolute;
    z-index: 100;
    width: 200px;
    bottom: 100px;
    right: 20px;
    padding: 10px;
}
